.thank-you-wrapper {
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

html,
body {
  height: 100%;
}

.thank-you-wrapper footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.thank-you-wrapper main {
  min-height: calc(100% - 78px - 374px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.thank-you__main span {
  color: rgb(149, 149, 149);
}

.thank-you__email {
  color: rgb(149, 149, 149);
  text-decoration: none;
}

.body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.25;
  background-color: #fff;
}

.link {
  color: #000 !important;
}

img {
  width: auto;
  height: auto !important;
  max-width: 100% !important;
  display: block !important;
}

.heading1 {
  font-size: 45px;
  font-weight: 700;
}

.heading2 {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}

.text {
  font-family: "Poppins", sans-serif;
  padding-top: 0px;
}

.bold {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.button {
  border-radius: 2px;
  padding-top: 18px;
  padding-bottom: 18px;
  display: inline-block;
  color: inherit;
  text-decoration: none;
  border: none;
  text-transform: uppercase;
  transition: 0.2s ease;
  outline: none;
}

.button:hover {
  color: inherit;
}

.button--primary {
  background-color: #000;
  color: #fff;
  border-radius: 2px;
  padding-left: 99px;
  padding-right: 99px;
  border: 1px solid #000;
  font-weight: 700;
}

.button--primary:hover {
  color: #000;
  background-color: #fff;
}

.button--submit {
  height: 100%;
  max-height: 72px;
  padding-left: 42px;
  padding-right: 42px;
  border-radius: 0;
  color: #fff;
  background-color: #000;
}

.button--submit:hover {
  color: #fff;
}

.button--ghost {
  border: 1px solid #464646;
  color: #464646;
}

.response-wrapper {
  max-width: 100%;
  margin: 0 40px;
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 51;
}

.response {
  padding: 20px 100px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.header {
  padding: 24px 0 0 0;
}

.header__cta {
  padding: 16px 52px;
}

/* .hero-wrapper {
  background-image: url("../images/hero-bg.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
} */

.hero__ar-mode-wrapper {
  position: relative;
}

.hero__image {
  width: 100%;
}

.hero__ar-mode {
  border-radius: 25px;
  padding: 8px 12px;
  margin-bottom: -70px;
  position: absolute;
  top: 125px;
  transform: translateX(-50%);
}

.hero__newsletter {
  padding: 113px 0 0 0;
}

.newsletter__email-wrapper {
  padding-bottom: 25px;
}

.newsletter__email {
  padding: 15px 15px 15px 55px;
  border: 0;
  outline: none;
  border-bottom: 1px solid #000;
}

.mc-field-group {
  position: relative;
}

.newsletter__icon {
  position: absolute;
  top: 37px;
  transform: translateY(-50%);
  left: 10px;
}

/* .try-it-now {
  background: url("../images/tryit_bg.svg") no-repeat;
  background-position: bottom;
  padding-bottom: 243px;
  margin-bottom: 210px;
} */

.try-it-now__heading {
  margin-top: 115px;
  padding-bottom: 67px;
}

.try-it-now__cta {
  padding-top: 30px;
}

.integration {
  margin-bottom: 256px;
}

.integration__heading {
  margin-bottom: 125px;
}

.integration__image {
  margin-bottom: 25px;
}

.brandable__content {
  margin-bottom: 75px;
}

.brandable__image {
  width: auto !important;
}

.downloading__heading {
  padding: 230px 0 56px 0;
}

.downloading__image {
  width: auto !important;
}

.testimonials__heading {
  padding: 225px 0 85px 0;
}

.testimonial {
  padding: 40px;
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 1px;
  flex-direction: row;
  flex-wrap: wrap;
}

.testimonial__top {
  height: 20px;
  width: auto;
}

.testimonial__bottom {
  margin-top: 40px;
  display: flex;
  align-self: flex-end;
}

.frames__heading {
  padding: 215px 0 85px 0;
}

.frames__button {
  margin-top: 50px;
}

.works {
  font-size: 30px;
  font-weight: 600;
  margin: 250px 0 100px 0;
}

/* .apple-icon:before {
  content: url("../images/ios.svg");
  height: 30px;
} */
/* 
.android-icon:before {
  content: url("../images/android.svg");
  height: 30px;
} */

.learn-more__newsletter {
  padding: 40px 0 180px 0;
}

.mce-responses {
  margin-top: 20px;
}

.footer {
  font-family: "Poppins", sans-serif;
  padding: 45px 0;
  background-color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer__links {
  padding: 35px 0 30px 0;
  display: flex;
}

.footer__link {
  font-family: "Poppins", sans-serif;
  color: rgb(149, 149, 149);
  padding-right: 32px;
}

.footer__link {
  color: rgb(149, 149, 149);
  padding: 0 32px;
  text-decoration: none;
}

.footer__link:hover {
  color: rgb(149, 149, 149);
}

.footer__link:not(.footer__link:last-child) {
  border-right: 1px solid rgb(149, 149, 149);
}

.footer__copyright {
  font-family: "Poppins", sans-serif;
  color: #1f1f1f;
}

.hoverable:hover {
  opacity: 0.7;
}
